import React, {Component} from "react";
import Header from "./Components/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";

import GiteaSVG from './images/gitea.svg';

export default class App extends Component {

  scrollTo = () => {

  }

  render() {
    
    return (
      <div>
        <Header scrollTo={this.scrollTo} />
        <div className="mt-36 mx-4">
          <div id="about">
            <h1 className="text-2xl font-bold">About Me</h1>
            <div className="flex flex-col ml-4">
              <div className="mb-2">
                Following my passion for creating software, I graduated with my B.S. in Computer Science from Full Sail University in 2023. 
              </div>
              <div className="mb-2">
                My passion for creating software stems from the enjoyment I get from improving workplace productivity or improving access to information.
              </div>
              <div>
                I have experience developing web applications in React.js and AngularJS and have experience coding in .NET, C#, Python, PHP and Java.
              </div>
            </div>
          </div>
          <div id="skills" className="my-4">
            <div className="text-2xl font-bold">Skills</div>
            <div className="ml-4 flex flex-col lg:flex-row lg:justify-around">
              <div>
                <div className="font-bold underline">
                  Languages
                </div>
                <ul className="ml-4">
                  <li>C#</li>
                  <li>HTML5 / CSS</li>
                  <li>Javascript</li>
                  <li>PHP</li>
                  <li>Python</li>
                </ul>
              </div>
              <div className="ml-4">
                <div>
                  <div className="font-bold underline">
                    Frameworks
                  </div>
                  <ul className="ml-4">
                    <li>React.js</li>
                    <li>AngularJS</li>
                    <li>FastAPI</li>
                    <li>Bootstrap</li>
                    <li>TailwindCSS</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div id="expierence" className="my-4">
            
          </div>
          <div id="contact">
            <h1 className="text-2xl font-bold">Links</h1>
            <div className="flex flex-row justify-around items-center"> 
              <a className="flex flex-row items-center" rel="noreferrer" href="https://git.lindelof.homes/clindelof" target="_blank"><img src={GiteaSVG} alt="gitea logo" height="48" width="48"/><div className="ml-2 font-bold">Git</div></a>
              <a className="flex flex-row items-center" rel="noreferrer" href="https://blog.chrislindelof.com" target="_blank"><img src={require('./images/ghost-logo-orb.png')} alt="ghost blog logo" height="48" width="48"/><div className="ml-2 font-bold">Blog</div></a>
              <a className="flex flex-row items-center" rel="noreferrer" href="https://www.linkedin.com/in/christopher-lindelof-3280aa282" target="_blank"><FontAwesomeIcon icon={faLinkedin} size="3x" /><div className="ml-2 font-bold">LinkedIn</div></a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
import React, {Component} from "react";
export default class Header extends Component {

    constructor(props) {
        super(props)

        this.state = {
            width: window.innerWidth,
            expand: false
        }
    }

    componentDidMount() {
        window.addEventListener('resize', () => {this.setState({width: window.innerWidth})})
    }

    render () {
        return (
            <div className="flex flex-col">
                <div className="text-xl font-bold bg-red-500 fixed top-0 left-0 right-0 flex flex-row items-center justify-end h-20 px-4">
                    <div>
                        <img className="fixed top-2 w-32 h-32 rounded-full" style={{left: 'calc((100vw - 8rem) / 2)'}} src={require('../images/PersonalProfile.jpeg')} alt="Portrait of Chris Lindelof"/>
                    </div>
                </div>
            </div>
        )
    }
}